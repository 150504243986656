.NoteComponentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .noContent {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: 200;
  }

  .textEditor {
    // width: 100%;
    // height: 1px;
  }

  .ql-toolbar {
    // position: sticky;
    // background-color: red;
  }
  .ql-container {
    overflow-x: auto;
    // height: 50%; /* whatever you what */
    // max-height: 80%;
  }

  .content {
    padding: 20px;
    flex-direction: column !important;
    height: 1px; //symbolic height to add vertical scroll to this component only
    overflow: auto;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
