.noteHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 20px;

  .title {
    font-size: 2em;
  }
  .icons {
    border-radius: 15%;
    padding: 5px;
    &:hover {
      cursor: pointer;

      background-color: rgba(150, 150, 150, 0.3);
    }
  }
  strong {
    margin-right: 20px;
  }
}
