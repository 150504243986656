.headerContainer {
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0px 1px 2px rgb(194, 194, 194);

  .logoContainer {
    max-width: 70px;
  }

  strong {
    margin-right: 20px;
    margin-left: 12px;
  }

  .searchBar {
    margin: 0px 6%;
    width: 50%;
  }

  .accountInfo,
  .leftHeader {
    display: flex;
    align-items: center;

    .icon {
      font-size: large;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .leftHeader {
    flex-grow: 1;
  }
}
