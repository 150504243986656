.leftPanel {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fafcfc;

  .leftPanelHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 10px;

    .icons {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15%;
      padding: 5px;
      &:hover {
        cursor: pointer;

        background-color: rgba(150, 150, 150, 0.3);
      }
    }
  }

  .leftPanelClose {
    display: flex !important;
    flex-direction: column-reverse !important;
    .icons {
      margin-bottom: 10px;
    }
  }
  .tree {
    background-color: #fafcfc;
    font-weight: bold;

    .addNote {
      padding: 2px;
      &:hover {
        background-color: rgb(17, 175, 248);
        border-radius: 50%;
      }
    }
  }
}

// width of the LeftPanel
.minWidth {
  width: 5%;
  transition: width 0.3s, height 0.3s, transform 2s;
}

.maxWidth {
  width: 20%;
  transition: width 0.3s, height 0.3s, transform 2s;
}
