.loggedInContainer {
  height: 100vh;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  .header {
    display: flex-start;
    align-items: center;
    padding: 5px 0px;
    padding-left: 20%;
    height: 5vh;
  }
  .loggedInForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: solid red;
    height: 90vh;
    .textFieldContainer,
    .buttonContainer {
      width: 200px;
      margin: 10px 0px;
    }
  }
}
